import { gql } from '@apollo/client';

const CREATE_ADDITIONAL_QUESTION = gql`
  mutation createAdditionalQuestion($question: String!, $tournament: Int!, $fieldType: String, $tournamentCategories: [Int!], $fieldOptions: String, $askEachAthlete: Boolean!, $isMandatory: Boolean) {
    createAdditionalQuestion(
      createAdditionalQuestionInput: {
        question: $question
        tournament: $tournament
        fieldType: $fieldType
        tournamentCategories: $tournamentCategories
        fieldOptions: $fieldOptions
        askEachAthlete: $askEachAthlete
        isMandatory: $isMandatory
      }
    ) {
      id
      question
      fieldType
      fieldOptions
    }
  }
`;

export default CREATE_ADDITIONAL_QUESTION;
