import { gql } from '@apollo/client';

const ADD_DRAW_ENTRY = gql`
  mutation addDrawEntry($id: Int!, $entry1: Int, $entry2: Int, $score: String, $winner: Int, $draw: Int) {
    addDrawEntry(updateDrawEntryInput: { id: $id, entry1: $entry1, entry2: $entry2, score: $score, winner: $winner, draw: $draw }) {
      id
      entry1 {
        id
        additionalSegment
        assignedSegment
        seed
        users {
          id
          rank
          blitzElo
          rapidElo
          standardElo
          status
          isDelete
          createdAt
          updatedAt
          withdrawReason
          fideTitle
          user {
            name
            surname
            nation
            avatar
            citizenship
            email
            phone
            dob
            id
          }
        }
      }
      entry2 {
        id
        additionalSegment
        assignedSegment
        seed
        users {
          id
          rank
          blitzElo
          rapidElo
          standardElo
          status
          isDelete
          createdAt
          updatedAt
          withdrawReason
          fideTitle
          user {
            name
            surname
            nation
            avatar
            citizenship
            email
            phone
            dob
            id
          }
        }
      }
    }
  }
`;

export default ADD_DRAW_ENTRY;
