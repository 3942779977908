import { gql } from 'src/__generated__/gql';

const LIVE_STANDING = gql(`
  query liveStanding($id: Int!) {
    liveStanding(id: $id) {
      id
      title
      tournament {
        id
        title
      }
      tournamentCategory {
        id
        category {
          name
        }
      }
      segment
      standings {
        id
        place
        points
        pointsRatio
        setRatio

        entry {
          id
          place
          group {
            name
          }
          registrationRequest {
            id
            segment
            assignedSegment
            additionalSegment
            seed
            users {
              id
              user {
                id
                email
                name
                surname
                avatar
                nation
              }
            }
          }
        }
      }
    }
  }
`);
export default LIVE_STANDING;
