import { gql } from '@apollo/client';
const allGroupsStandings = gql`
  query allGroupsStandings($page: Int, $limit: Int, $filter: ListGroupInput, $queryName: String, $name: String) {
    allGroupsStandings(page: $page, limit: $limit, filter: $filter, queryName: $queryName, name: $name) {
      groups {
        id
        name
        round
        tournament {
          id
          title
        }
        segment
        tournamentCategory {
          id
          category {
            name
          }
        }
        displayPlayOrder
        displayMatches
        participantsAddScore
        hide
        comment
        isDelete
        groupType
        entries {
          id
          place
          registrationRequest {
            id
            seed
            segment
            assignedSegment
            additionalSegment
            users {
              id
              user {
                id
                email
                name
                surname
                nation
              }
            }
          }
        }
      }
      total
    }
  }
`;
export default allGroupsStandings;
