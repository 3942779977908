import React, { createContext, useContext, useState, FC, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';

interface GroupsContextProps {
  limit: number;
  setLimit: (limit: number) => void;
  page: number;
  setPage: (page: number) => void;
  debouncedSearch: string;
  search: string;
  setSearch: (search: string) => void;
}

const GroupsContext = createContext<GroupsContextProps | undefined>(undefined);

export const GroupsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [filterParams, setFilterParams] = useSearchParams();
  const [limit, setLimit] = useState<number>(filterParams.get('limit') ? parseInt(filterParams.get('limit')) : 8);
  const [page, setPage] = useState<number>(filterParams.get('page') ? parseInt(filterParams.get('page')) - 1 : 0);
  const [search, setSearch] = useState<string>(filterParams.get('search') || '');
  const debouncedSearch = useDebouncedTerm(search, 500);

  return <GroupsContext.Provider value={{ limit, setLimit, page, setPage, search, debouncedSearch, setSearch }}>{children}</GroupsContext.Provider>;
};

export const useGroups = (): GroupsContextProps => {
  const context = useContext(GroupsContext);
  if (!context) {
    throw new Error('useGroups must be used within a GroupsProvider');
  }
  return context;
};
