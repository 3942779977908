import { gql } from '@apollo/client';

const UPDATE_ADDITIONAL_QUESTION = gql`
  mutation updateAdditionalQuestion(
    $id: Int!
    $question: String!
    $tournament: Int!
    $fieldType: String
    $tournamentCategories: [Int!]
    $fieldOptions: String
    $askEachAthlete: Boolean!
    $isMandatory: Boolean
  ) {
    updateAdditionalQuestion(
      updateAdditionalQuestionInput: {
        id: $id
        question: $question
        tournament: $tournament
        fieldType: $fieldType
        tournamentCategories: $tournamentCategories
        fieldOptions: $fieldOptions
        askEachAthlete: $askEachAthlete
        isMandatory: $isMandatory
      }
    ) {
      id
      question
      fieldType
      fieldOptions
      askEachAthlete
    }
  }
`;

export default UPDATE_ADDITIONAL_QUESTION;
