import { Dispatch, FC, SetStateAction, useState, useEffect, Fragment } from 'react';
import { Autocomplete, Box, Button, CircularProgress, Divider, LinearProgress, Modal, Paper, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Scrollbar from 'src/components/Scrollbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import useAuth from 'src/hooks/useAuth';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CALCULATE_LIVE_STANDING_POINTS, LIVE_STANDINGS_IDS, RANKING_POINTS, TOURNAMENT_BY_ID } from 'src/graphql/queries';
import { useNavigate } from 'react-router';
import { CREATE_RANKING_POINTS } from 'src/graphql/mutations';
import { useTranslation } from 'react-i18next';

interface RankingsModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}
const RankingsMoodal: FC<RankingsModalProps> = ({ openModal, setOpenModal }) => {
  const [liveStandingRowsData, setLiveStandingRowsData] = useState<any[]>([]);
  const [liveStandingsData, setLiveStandingsData] = useState<any[]>([]);
  const [rankingPointsId, setRankingPointsId] = useState<number>(null);
  const [places, setPlaces] = useState<any[]>([]);
  const [category, setCategory] = useState<number>(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data } = useQuery(TOURNAMENT_BY_ID, {
    variables: {
      id: auth.tournamentId,
    },
  });

  const [getStandings, { loading: standingLoading, data: liveStandingsTableData }] = useLazyQuery(LIVE_STANDINGS_IDS);
  const [getCalculateStandings, { loading }] = useLazyQuery(CALCULATE_LIVE_STANDING_POINTS);

  const getLiveStanding = async (catId) => {
    try {
      await getStandings({
        variables: {
          filter: {
            tournament: auth.tournamentId,
            tournamentCategory: parseInt(catId),
          },
        },
      });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getLiveStandingCalculated = async (standingId) => {
    try {
      const res = await getCalculateStandings({
        variables: {
          id: parseInt(standingId),
          // category: category,
        },
      });
      setLiveStandingsData(res?.data?.calculateLiveStandingPoints?.liveStandingPoints || []);
      setLiveStandingRowsData(res?.data?.calculateLiveStandingPoints?.liveStandingPoints || []);
      setPlaces(res?.data?.calculateLiveStandingPoints?.liveStandingPlaces || []);
      setRankingPointsId(res?.data?.calculateLiveStandingPoints?.rankingPointsId);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const [createRankingPoints] = useMutation(CREATE_RANKING_POINTS, {
    refetchQueries: ['rankingPoints'],
    awaitRefetchQueries: true,
  });

  const updateLiveStandingRowsData = (index, newValues) => {
    setLiveStandingRowsData((prev) => {
      const updated = prev.map((item, i) => (i === index ? { ...item, ...newValues } : item));
      return updated;
    });
  };

  const findCurrentItem = (index) => {
    return liveStandingRowsData?.find((item, i) => i === index);
  };

  const yupSchema = Yup.object().shape({
    category: Yup.string().max(255).required(t('Category is Required Field')),
    standing: Yup.number().required(t('Live Standing is Required Field')),
  });

  const handleClose = () => {
    setLiveStandingRowsData([]);
    setLiveStandingsData([]);
    setOpenModal(false);
  };

  return (
    <Fragment>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '802px',
            borderRadius: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 10,
            height: '100%',
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              // name: '',
              standing: '',
              category: '',
              // segment: '',
            }}
            validationSchema={yupSchema}
            onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }): Promise<void> => {
              try {
                const updatedValues = liveStandingRowsData
                  ?.filter((item) => item?.entry?.registrationRequest?.id)
                  ?.map((item, index) => {
                    return {
                      place: item?.place,
                      points: item?.points || 0,
                      entry: item?.entry?.registrationRequest?.id,
                    };
                  });
                const variables = {
                  tournament: auth.tournamentId,
                  tournamentCategory: parseInt(values.category),
                  // name: values.name,
                  liveStanding: parseInt(values.standing),
                  rankings: updatedValues,
                  league: data?.tournamentDetailById?.league?.league?.id,
                  rankingPoint: rankingPointsId,
                };
                await createRankingPoints({
                  variables,
                });

                handleClose();
                resetForm();
                toast.success(t('Results added successfully'));
                navigate(`/dashboard/tournament-results?tournament=${auth.tournamentId}`);
              } catch (err) {
                toast.error(err?.message);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldTouched, setFieldValue, touched, values }): JSX.Element => (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Paper
                  elevation={12}
                  sx={{
                    width: '100%',
                    maxHeight: '90vh',
                    background: '#FFF',
                    p: 0.5,
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '7px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#aaa',
                      borderRadius: '6px',
                      pl: 3,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#a2a2a2',
                    },
                  }}
                >
                  <Scrollbar>
                    <Box
                      sx={{
                        padding: '20px 20px 0px 20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography color="textPrimary" variant="h2">
                        {t('Submit Results to Rankings')}
                      </Typography>
                      <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
                    </Box>
                    <Box sx={{ padding: '20px 20px 0px 20px' }}>
                      <Divider />
                    </Box>
                    <Box sx={{ padding: '20px 20px 20px 20px' }}>
                      {/* <TextField
                        error={Boolean(touched?.name && errors.name)}
                        fullWidth
                        helperText={touched?.name && (errors.name as any)}
                        label="Ranking Points Title"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      /> */}
                      <TextField
                        error={Boolean(touched.category && errors.category)}
                        fullWidth
                        helperText={touched.category && (errors.category as any)}
                        label={t('Select Category')}
                        name="category"
                        onChange={(e) => {
                          setCategory(parseInt(e.target.value));
                          getLiveStanding(e.target.value);
                          setFieldValue('category', e.target.value);
                          setLiveStandingsData([]);
                          setLiveStandingRowsData([]);
                          setFieldValue('standing', '');
                        }}
                        select
                        SelectProps={{ native: true }}
                        value={values.category}
                        variant="outlined"
                        sx={{ mt: 2 }}
                      >
                        <option></option>

                        {data?.tournamentDetailById?.tournamentCategory?.length > 0 ? (
                          data?.tournamentDetailById?.tournamentCategory?.map((category) => (
                            <option key={category?.id} value={category?.id}>
                              {category?.category?.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>{t('No category found')}</option>
                        )}
                      </TextField>

                      <TextField
                        error={Boolean(touched.standing && errors.standing)}
                        fullWidth
                        helperText={touched.standing && (errors.standing as any)}
                        label={t('Select Live Standing')}
                        name="standing"
                        onChange={async (e) => {
                          setFieldValue('standing', e.target.value);
                          getLiveStandingCalculated(e.target.value);
                        }}
                        select
                        SelectProps={{ native: true }}
                        value={values.standing}
                        variant="outlined"
                        sx={{ mt: 2 }}
                        InputProps={{
                          endAdornment: standingLoading ? <CircularProgress color="inherit" size={20} /> : null,
                        }}
                      >
                        <option></option>

                        {liveStandingsTableData?.liveStandings?.length > 0 ? (
                          liveStandingsTableData?.liveStandings?.map((standing) => (
                            <option key={standing?.id} value={standing?.id}>
                              {standing?.title}
                            </option>
                          ))
                        ) : (
                          <option disabled>{t('No Live Standings found')}</option>
                        )}
                      </TextField>

                      {loading && (
                        <Box
                          sx={{
                            mt: 4,
                            mb: 2,
                            px: 0.5,
                          }}
                        >
                          <LinearProgress />
                        </Box>
                      )}

                      {liveStandingsData?.length > 0 &&
                        liveStandingsData?.map((row, index) => (
                          <Box
                            key={index}
                            sx={{
                              mt: 1,
                              marginBottom: '5px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              gap: '10px',
                              '@media (max-width: 560px)': {
                                flexWrap: 'wrap',
                              },
                            }}
                          >
                            <TextField
                              // error={Boolean(touched?.place && errors.place)}
                              fullWidth
                              // helperText={touched?.place && (errors.place as any)}
                              label={t('Select place')}
                              name="place"
                              onChange={(e) => {
                                updateLiveStandingRowsData(index, { place: e.target.value });
                                updateLiveStandingRowsData(index, { points: places?.find((item) => item?.place === e.target.value)?.points });
                              }}
                              select
                              SelectProps={{ native: true }}
                              value={findCurrentItem(index)?.place}
                              variant="outlined"
                              sx={{ mt: 2 }}
                            >
                              <option></option>
                              {places?.map((placeItem, placeIndex) => (
                                <option key={placeItem?.id} value={placeItem?.place}>
                                  {placeItem?.place}
                                </option>
                              ))}
                            </TextField>
                            <Autocomplete
                              fullWidth
                              options={liveStandingsData || []}
                              getOptionLabel={(option) => option?.entry?.registrationRequest?.users?.map((user) => `${user?.user?.name} ${user?.user?.surname}`).join(' / ') || ''}
                              value={liveStandingsData?.find((option) => option?.entry?.registrationRequest?.id === findCurrentItem(index)?.entry?.registrationRequest?.id)}
                              onChange={(event, newValue) => {
                                updateLiveStandingRowsData(index, { entry: newValue?.entry });
                              }}
                              sx={{ background: '#FBFBFB' }}
                              renderInput={(params) => (
                                <TextField {...params} label={row?.entry?.registrationRequest?.users?.length > 1 ? t('Select Pair') : t('Select Player')} variant="outlined" sx={{ mt: 2 }} />
                              )}
                            />
                            <TextField
                              // error={Boolean(touched?.points && errors.points)}
                              fullWidth
                              // helperText={touched?.points && (errors.points as any)}
                              label={t('Points')}
                              name="points"
                              onChange={(e) => {
                                updateLiveStandingRowsData(index, { points: parseFloat(e.target.value) });
                              }}
                              value={findCurrentItem(index)?.points || 0}
                              variant="outlined"
                              type="number"
                              sx={{ mt: 2 }}
                            />
                          </Box>
                        ))}
                    </Box>
                    <Box
                      sx={{
                        padding: '0 20px 20px',
                        marginBottom: '5px',
                        display: 'flex',
                        gap: '10px',
                        '@media (max-width: 560px)': {
                          flexWrap: 'wrap',
                        },
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          display: 'inline-flex',
                          height: '50px',
                          padding: '10px 24px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                          // background: "#29ABE2",
                          color: '#FFF',
                          textTransform: 'uppercase',
                        }}
                        type="submit"
                        disabled={isSubmitting}
                        startIcon={isSubmitting ? <CircularProgress color="inherit" size={20} /> : null}
                      >
                        {t('Submit')}
                      </Button>
                    </Box>
                  </Scrollbar>
                </Paper>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default RankingsMoodal;
