import { gql } from 'src/__generated__/gql';

const ENTRIES = gql(`
  query tournamentRegistrations($filter: ListTournamentRegistrationInput) {
    tournamentRegistrations(filter: $filter) {
      id
      type
      status
      rankType
      createdAt
      assignedSegment
      additionalSegment
      seed
      amount
      segment
      paymentMethod
      paymentStatus
      additionalQuestions {
        id
        question {
          id
          question
          fieldOptions
        }
        answer
        file
        fieldType
        amount
        user {
          id
          email
          name
          surname
        }
      }
      categoryId {
        mdwc
        qwc
        qs
      }
      team {
        title
        country
        image
      }
      users {
        id
        fideTitle
        rank
        blitzElo
        standardElo
        rapidElo
        duprRating
        user {
          duprId
          rank
          id
          name
          phone
          surname
          avatar
          nation
          citizenship
          email
          gender
          dob
          duprId
        }
      }
    }
  }
`);

export default ENTRIES;
