import { gql } from '@apollo/client';
const allGroupMatches = gql`
  query allGroupMatches($page: Int, $limit: Int, $filter: ListGroupInput, $queryName: String, $name: String) {
    allGroupMatches(page: $page, limit: $limit, filter: $filter, queryName: $queryName, name: $name) {
      groups {
        id
        name
        matches {
          isDelete
          score {
            round
            score
            roundFormat
            entry {
              id
            }
          }
          id
          court {
            name
            id
          }
          date
          time
          round
          conflict
          winner {
            id
          }
          winnerPartner {
            id
          }
          status
          matchStatus
          scoreType
          isMatchInProgress
          # hideInOOP
          entry1 {
            id
            registrationRequest {
              users {
                user {
                  id
                  email
                  name
                  surname
                  nation
                }
              }
            }
          }
          entry1partner {
            id
            registrationRequest {
              users {
                user {
                  id
                  email
                  name
                  surname
                  nation
                }
              }
            }
          }
          entry2 {
            id
            registrationRequest {
              users {
                user {
                  id
                  email
                  name
                  surname
                  nation
                }
              }
            }
          }
          entry2partner {
            id
            registrationRequest {
              users {
                user {
                  id
                  email
                  name
                  surname
                  nation
                }
              }
            }
          }
        }
      }
      total
    }
  }
`;
export default allGroupMatches;
