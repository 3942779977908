import { Fragment, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Checkbox,
  Avatar,
  Tooltip,
  CircularProgress,
  Grid,
  AvatarGroup,
} from '@material-ui/core';
import SearchIcon from 'src/icons/Search';
import type { LiveStandings } from 'src/types/liveStandings';
import Scrollbar from 'src/components/Scrollbar';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import GetFlag from 'src/components/shared/GetFlag';
import { useSelector } from 'react-redux';
import ExcelJS from 'exceljs';
import { formatSeed } from 'src/constants';
import { ReactComponent as Delete } from 'src/assets/images/delete.svg';
import { WhiteButton } from 'src/components/shared/Buttons';
import toast from 'react-hot-toast';
import { HelpOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface LiveStandingsListTableProps {
  liveStandingsData: LiveStandings[];
  title?: string;
  category?: string;
  segment?: string;
  liveStandingID: number;
  isSingleSelected?: boolean;
  handleSelectOneLiveStanding?: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
  categories?: any;
}

type Sort = 'all' | 'updatedAt|asc';

interface SortOption {
  value: Sort;
  label: string;
}

const sortOptions: SortOption[] = [
  {
    label: 'All',
    value: 'all',
  },
];

const applyFilters = (liveStandingsData: LiveStandings[], query: string, filters: any): LiveStandings[] =>
  liveStandingsData?.filter((LiveStandings) => {
    let matches = true;

    if (query) {
      const searchWords = query.toLowerCase().split(' ');
      const properties = ['name', 'surname'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (searchWords.every((word) => LiveStandings?.entry?.registrationRequest?.users.some((user) => user.user[property]?.toLowerCase().includes(word)))) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && LiveStandings[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (customers: LiveStandings[], page: number, limit: number): LiveStandings[] => customers?.slice(page * limit, page * limit + limit);

const descendingComparator = (a: LiveStandings, b: LiveStandings, orderBy: string): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) =>
  order === 'desc' ? (a: LiveStandings, b: LiveStandings) => descendingComparator(a, b, orderBy) : (a: LiveStandings, b: LiveStandings) => -descendingComparator(a, b, orderBy);

const applySort = (customers: LiveStandings[], sort: Sort): LiveStandings[] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis?.map((el) => el[0]);
};

const segmentOptions = [
  {
    value: 'main',
    label: 'Main',
  },
  {
    value: 'consolation',
    label: 'Consolation',
  },
  {
    value: 'qualification',
    label: 'Qualification',
  },
];

const LiveStandingsListTable: FC<LiveStandingsListTableProps> = (props) => {
  const { liveStandingsData, title, category, segment, liveStandingID, handleSelectOneLiveStanding, isSingleSelected, categories, ...other } = props;
  const { t } = useTranslation();
  const [selectedLiveStandings, setSelectedLiveStandings] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });
  const enableBulkActions = selectedLiveStandings.length > 0;
  const selectedSomeLiveStandings = selectedLiveStandings.length > 0 && selectedLiveStandings.length < liveStandingsData?.length;
  const selectedAllLiveStandings = selectedLiveStandings.length === liveStandingsData?.length;

  const handleSelectAllLiveStandings = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedLiveStandings(event.target.checked ? liveStandingsData?.map((LiveStandings) => LiveStandings.id) : []);
  };

  const handleSelectOneDocument = (event: ChangeEvent<HTMLInputElement>, customerId: string): void => {
    if (!selectedLiveStandings.includes(customerId)) {
      setSelectedLiveStandings((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedLiveStandings((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(event.target.value as Sort);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setQuery('');
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredDocuments = applyFilters(liveStandingsData, query, filters);
  const sortedDocuments = applySort(filteredDocuments, sort);
  const paginatedDocuments = applyPagination(sortedDocuments, page, limit);
  const navigate = useNavigate();

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    let headers = ['Place', 'Team', 'Points', 'Set Ratio', 'Points Ratio'];

    worksheet.addRow(headers);

    filteredDocuments.forEach((liveStanding, index) => {
      const row = [
        liveStanding?.place,
        liveStanding?.entry?.registrationRequest?.users[0]?.user?.name + ' ' + liveStanding?.entry?.registrationRequest?.users[0]?.user?.surname || 'Not Added',
        liveStanding?.points,
        liveStanding?.setRatio,
        liveStanding?.pointsRatio,
      ];

      const excelRow = worksheet.addRow(row);

      excelRow.eachCell((cell) => {
        cell.alignment = { horizontal: 'left' };
      });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'participents_data.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={1} flexWrap="wrap">
        <Grid item xs={6}>
          <Typography
            sx={{
              minWidth: '200px',
              color: '#1C3962',
              pb: '4px',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              // borderBottom: '2px solid #edeaea',
            }}
          >
            <Checkbox
              checked={isSingleSelected}
              onChange={(event) => {
                event.stopPropagation();
                handleSelectOneLiveStanding(event, liveStandingID);
              }}
              value={isSingleSelected}
              sx={{
                color: '#D3D3D3',
                p: 0,
                mr: 1,
                mt: '-6px',
                '@media print': {
                  display: 'none !important',
                },
              }}
            />
            {title + ' '}
            {!categories?.length && <sup>({category})</sup>}
          </Typography>
          {/* <Box
            sx={{
              maxWidth: '100%',
              width: 253,
            }}
          >
            <TextField
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="small" sx={{ color: 'black' }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search"
              value={query}
              variant="outlined"
            />
          </Box> */}
        </Grid>
        <Grid item xs={6}>
          {enableBulkActions && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              {/* <Button
              color="primary"
              sx={{
                backgroundColor: 'background.paper',
                marginBottom: '6px',
                border: '1px solid #E3E3E3',
                '& svg': {
                  verticalAlign: 'middle',
                },
              }}
              variant="outlined"
            >
              <ReactSVG src="/images/refresh.svg" />{' '}
              <Typography
                sx={{
                  color: '#182C47',
                  fontWeight: 500,
                  marginLeft: '7px',
                  marginTop: '4px',
                }}
                variant="body1"
              >
                Update
              </Typography>
            </Button>
            <Button
              color="primary"
              sx={{
                backgroundColor: 'background.paper',
                marginBottom: '6px',
                ml: 2,
                border: '1px solid #E3E3E3',
                '& svg': {
                  verticalAlign: 'middle',
                },
              }}
              variant="outlined"
            >
              <ReactSVG src="/images/copy.svg" />{' '}
              <Typography
                sx={{
                  color: '#182C47',
                  fontWeight: 500,
                  marginLeft: '7px',
                  marginTop: '4px',
                }}
                variant="body1"
              >
                Copy
              </Typography>
            </Button> */}
              <WhiteButton
                sx={{
                  border: '1px solid #E0E0E0',
                  height: '40px',
                  textTransform: 'uppercase',
                }}
                variant="contained"
                startIcon={<Delete className="svg-current-color" style={{ marginBottom: '3px' }} />}
                onClick={() => toast.success('Under construction ⚒️')}
              >
                {t('Delete')}
              </WhiteButton>
            </Box>
          )}
        </Grid>
      </Grid>
      {liveStandingsData?.length > 0 && (
        <Card
          {...other}
          sx={{
            boxShadow: 'none',
            border: '1px solid #EDF1F6',
            borderRadius: '6px',
          }}
        >
          <Scrollbar>
            <Box sx={{ minWidth: 700, padding: '0 12px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox checked={selectedAllLiveStandings} sx={{ color: '#D3D3D3' }} indeterminate={selectedSomeLiveStandings} onChange={handleSelectAllLiveStandings} />
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'rgba(27, 56, 97, 0.6)',
                          fontWeight: 500,
                          whiteSpace: 'pre',
                        }}
                      >
                        {t('NR')}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'rgba(27, 56, 97, 0.6)',
                          fontWeight: 500,
                          whiteSpace: 'pre',
                          // paddingLeft: '20px',
                        }}
                      >
                        {t('Entry')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'rgba(27, 56, 97, 0.6)',
                          fontWeight: 500,
                          whiteSpace: 'pre',
                        }}
                      >
                        PIG
                        <Tooltip title="Place in Group" arrow>
                          <IconButton sx={{ color: 'gray' }} component="span" size="small">
                            <HelpOutline fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'rgba(27, 56, 97, 0.6)',
                          fontWeight: 500,
                          whiteSpace: 'pre',
                        }}
                      >
                        {t('Points')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'rgba(27, 56, 97, 0.6)',
                          fontWeight: 500,
                          whiteSpace: 'pre',
                        }}
                      >
                        {t('SR')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'rgba(27, 56, 97, 0.6)',
                          fontWeight: 500,
                          whiteSpace: 'pre',
                        }}
                      >
                        {t('PR')}
                      </Typography>
                    </TableCell>

                    {/* <TableCell align="right"></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedDocuments?.map((LiveStandings: any, index) => {
                    const isDocumentsSelected = selectedLiveStandings.includes(LiveStandings.id);

                    return (
                      <TableRow hover key={LiveStandings.id} selected={isDocumentsSelected} className="entry-row">
                        <TableCell padding="checkbox">
                          <Checkbox checked={isDocumentsSelected} sx={{ color: '#D3D3D3' }} onChange={(event) => handleSelectOneDocument(event, LiveStandings.id)} value={isDocumentsSelected} />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            {index + 1}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', textTransform: 'uppercase' }}>
                              {LiveStandings?.entry?.registrationRequest?.users.length === 2 ? (
                                <AvatarGroup
                                  max={4}
                                  sx={{
                                    justifyContent: 'start',
                                    '& .MuiAvatarGroup-avatar': {
                                      width: '35px',
                                      height: '35px',
                                      fontSize: '.6rem',
                                      cursor: 'pointer',
                                    },
                                  }}
                                >
                                  <Avatar src={LiveStandings?.entry?.registrationRequest?.users[0]?.user?.avatar} />
                                  <Avatar src={LiveStandings?.entry?.registrationRequest?.users[1]?.user?.avatar} />
                                </AvatarGroup>
                              ) : (
                                <Avatar
                                  sx={{
                                    width: '30px',
                                    height: '30px',
                                    fontSize: '.6rem',
                                    cursor: 'pointer',
                                  }}
                                  src={LiveStandings?.entry?.registrationRequest?.users[0]?.user?.avatar}
                                />
                              )}
                              <Box
                                sx={{
                                  minWidth: '190px',
                                  gap: '4px',
                                  flex: '1 0 0',
                                  marginLeft: '10px',
                                }}
                              >
                                {LiveStandings?.entry?.registrationRequest?.users?.map((user: any, index) => (
                                  <Box sx={{ display: 'flex' }} key={user?.id}>
                                    {user?.user?.name + ' ' + user?.user?.surname}
                                    {(LiveStandings?.entry?.registrationRequest?.seed || LiveStandings?.entry?.registrationRequest?.assignedSegment) &&
                                      index === 0 &&
                                      formatSeed(
                                        {
                                          seed: LiveStandings?.entry?.registrationRequest?.seed,
                                          assignedSegment: LiveStandings?.entry?.registrationRequest?.assignedSegment,
                                          additionalSegment: LiveStandings?.entry?.registrationRequest?.additionalSegment,
                                        },
                                        segment,
                                      )}
                                    <GetFlag country={user?.user?.nation} style={{ width: '1.3em', height: '1.3em', marginLeft: '8px' }} />
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            {`${LiveStandings?.entry?.group?.name?.split(' ')?.[1] || ''} ${LiveStandings?.entry?.group?.name ? '-' : ''} ${LiveStandings?.entry?.place || ''}`}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            {LiveStandings?.points}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            {LiveStandings?.setRatio}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" sx={{ color: '#193660', fontWeight: 500 }}>
                            {LiveStandings?.pointsRatio}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton component={RouterLink} to="/dashboard/under-construction" sx={{ padding: 0 }}>
                            <ReactSVG src="/images/pencil.svg" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Scrollbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
              mt: '10px',
            }}
          >
            <Box
              sx={{
                marginLeft: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                '@media (max-width: 525px)': {
                  mt: '10px',
                },
              }}
            >
              <Box
                sx={{
                  mr: '8px',
                  '@media (max-width: 525px)': {
                    mt: '8px',
                  },
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  startIcon={
                    <ReactSVG
                      src="/images/download-list.svg"
                      style={{
                        marginTop: '4px',
                        paddingLeft: '5px',
                      }}
                    />
                  }
                  sx={{ height: '36px', textTransform: 'uppercase', mt: -0.3 }}
                  variant="contained"
                  onClick={downloadExcel}
                >
                  {t('Download List')}
                </Button>
              </Box>
            </Box>
            <TablePagination
              sx={{ ml: 2 }}
              component="div"
              count={filteredDocuments?.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Card>
      )}
    </React.Fragment>
  );
};

LiveStandingsListTable.propTypes = {
  liveStandingsData: PropTypes.array.isRequired,
};

export default LiveStandingsListTable;
