import { gql } from "@apollo/client";

const ADD_ENTRIES = gql`
  mutation createRegistrationRequest(
    $tournament: Int!
    $type: String!
    $status: String!
    $categoryId: Int!
    $usersData: [JSONObject!]
    $user: [String!]
  ) {
    createRegistrationRequest(
      createTournamentRegistrationInput: {
        tournament: $tournament
        type: $type
        status: $status
        categoryId: $categoryId
        usersData: $usersData
        user: $user
      }
    ) {
      type
      status
    }
  }
`;

export default ADD_ENTRIES;
