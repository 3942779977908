import { gql } from '@apollo/client';
const GET_RANKINGS = gql`
  query tournamentRankings($filter: ListTournamentRankingInput!, $tournamentCategoryIds: [Int!]) {
    tournamentRankings(filter: $filter, tournamentCategoryIds: $tournamentCategoryIds) {
      tournamentRankings {
        id
        name
        isPublish
        tournament {
          id
          title
        }
        tournamentCategory {
          id
          category {
            name
            id
          }
        }
        # segment
        rankings {
          id
          place
          points
          entry {
            id
            # registrationRequest {
            id
            users {
              id
              user {
                id
                email
                name
                surname
                avatar
                nation
              }
            }
          }
          # }
        }
      }
    }
  }
`;
export default GET_RANKINGS;
