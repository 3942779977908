import { useQuery } from '@apollo/client';
import { Box, Button, CircularProgress, Container, InputAdornment, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import type { ChangeEvent, FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import NoData from 'src/components/dashboard/datatable/NoData';
import ActionButtons from 'src/components/dashboard/liveStandings/ActionButtons';
import LiveStandingsListTable from 'src/components/dashboard/liveStandings/LiveStandingsListTable';
import LiveStandingsMoodal from 'src/components/dashboard/liveStandings/LiveStandingsModal';
import RankingsMoodal from 'src/components/dashboard/liveStandings/RankingsModal';
import AutocompleteChip from 'src/components/shared/AutocompleteChip';
import Loading from 'src/components/shared/Loading';
import { LIVE_STANDINGS_DATA, TOURNAMENT_BY_ID } from 'src/graphql/queries';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import gtm from 'src/lib/gtm';

const useStyles = makeStyles((theme: any) => ({
  topBarButton: {
    height: '42px',
    mt: -0.3,
    backgroundColor: '#FFFF',
    color: '#425466',
    border: `1px solid ${theme.custom.borderColor}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFF',
    },
  },
  dropDown: {
    display: 'flex',
    borderRadius: '6px',
    border: '1px solid #EDF1F6',
    alignItems: 'center',
    paddingLeft: '8px',
    height: '42px',
    width: '250px',
    backgroundColor: '#FFFF',
  },
  topBarDropdown: {
    // backgroundColor: "#FFFF",
    // height: "42px",
    color: '#425466',
    '& .MuiSelect-select': {
      padding: '5px 14px 5px 5px',
    },
    '& .MuiNativeSelect-select': {
      padding: '0 14px',
      backgroundColor: '#FFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  topBarSearch: {
    backgroundColor: '#FFFF',
    '& .MuiInputBase-input': {
      paddingLeft: '8px',
      backgroundColor: '#FFFF',
    },
    '& .MuiOutlinedInput-root': {
      minHeight: '40px',
      maxHeight: '55px',
      borderRadius: '6px',
      border: '1px solid #EFEFF0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

const segmentOptions = [
  {
    label: 'Main',
    value: 'MD',
  },
  {
    label: 'Consolation',
    value: 'consolation',
  },
  {
    label: 'Qualification',
    value: 'Q',
  },
];

const LiveStandings: FC = () => {
  const { tournamentId } = useAuth();
  const [segment, setSegment] = useState<any>(null);
  const [categories, setCategories] = useState([]);
  const [openRankingModal, setOpenRankingModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedLiveStandings, setSelectedLiveStandings] = useState<number[]>([]);
  const { settings } = useSettings();
  const classes = useStyles();
  const { t } = useTranslation();

  const { loading, data: liveStandingsTableData } = useQuery(LIVE_STANDINGS_DATA, {
    variables: {
      filter: {
        tournament: tournamentId,
        ...(segment ? { segment: segment } : {}),
      },
      ...(categories.length > 0 ? { tournamentCategoryIds: categories?.map((cat) => cat.id) } : {}),
    },
    fetchPolicy: 'network-only',
  });

  const { data, loading: tourLoading } = useQuery(TOURNAMENT_BY_ID, {
    skip: !tournamentId,
    variables: {
      id: tournamentId,
    },
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleSelectOneLiveStanding = (event: ChangeEvent<HTMLInputElement>, groupId: number): void => {
    event.stopPropagation();
    if (!selectedLiveStandings.includes(groupId)) {
      setSelectedLiveStandings((prevSelected) => [...prevSelected, groupId]);
    } else {
      setSelectedLiveStandings((prevSelected) => prevSelected.filter((id) => id !== groupId));
    }
  };

  if (loading || tourLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('Dashboard: Live Standings Data')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#FAFAFA',
          minHeight: '100%',
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              py: '10px',
              justifyContent: 'flex-end',
              gap: '10px',
              '@media (max-width: 768px)': {
                mb: 0,
                justifyContent: 'flex-start',
              },
            }}
          >
            <Box sx={{ minWidth: '200px' }}>
              <AutocompleteChip
                placeholder={t('Categories')}
                options={data?.tournamentDetailById?.tournamentCategory || []}
                value={categories || []}
                getOptionLabel={(option) => `${option?.category?.name}`}
                handleChange={(e, newValue) => {
                  setCategories(newValue);
                }}
                size="small"
                background="#FFFF"
                borderColor="#EFEFF0"
                showPlusMore={true}
                handleSelectAllChange={(e) => {
                  if (e.target.checked) {
                    setCategories(data?.tournamentDetailById?.tournamentCategory);
                  } else {
                    setCategories([]);
                  }
                }}
              />
            </Box>
            <Box className={classes.dropDown}>
              <Typography sx={{ color: '#19366066' }}>{t('Segment: ')}</Typography>
              <TextField
                fullWidth
                name="category"
                select
                variant="outlined"
                className={classes.topBarDropdown}
                value={segment}
                onChange={(e) => dispatch(setSegment(e.target.value === 'null' ? null : e.target.value))}
                SelectProps={{
                  native: true,
                  endAdornment: (
                    <InputAdornment position="end" sx={{ right: '15px', position: 'absolute', height: 20, width: 20, background: '#fff' }}>
                      {loading && <CircularProgress size={20} />}
                    </InputAdornment>
                  ),
                }}
              >
                <option value="null">{t('All Segments')}</option>
                {segmentOptions.map((segment) => (
                  <option key={segment.value} value={segment.value}>
                    {segment.label}
                  </option>
                ))}
              </TextField>
            </Box>
            <Tooltip title={t('Add Live Standings')}>
              <Button
                sx={{
                  border: '1px solid #E0E0E0',
                  height: '36px',
                  textTransform: 'uppercase',
                }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setOpenModal(true)}
                className={classes.topBarButton}
              >
                {t('Add Live Standings')}
              </Button>
            </Tooltip>
            <Tooltip title={t('Submit Final Results')}>
              <Button
                sx={{
                  border: '1px solid #E0E0E0',
                  height: '36px',
                  textTransform: 'uppercase',
                }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setOpenRankingModal(true)}
                className={classes.topBarButton}
              >
                {t('Submit Final Results')}
              </Button>
            </Tooltip>
          </Box>
          <Box sx={{ position: 'relative' }}>
            {liveStandingsTableData?.liveStandings?.length > 0 ? (
              liveStandingsTableData?.liveStandings?.map(
                (item: any, index) => {
                  const isSingleSelected = selectedLiveStandings.includes(item.id);

                  return (
                    // item?.standings?.length > 0 && (
                    <Box sx={{ mt: index !== 0 && 3 }} key={item.id}>
                      <LiveStandingsListTable
                        liveStandingsData={item?.standings}
                        title={item?.title}
                        category={item?.tournamentCategory?.category?.name}
                        segment={item?.segment}
                        liveStandingID={item?.id}
                        handleSelectOneLiveStanding={handleSelectOneLiveStanding}
                        isSingleSelected={isSingleSelected}
                        categories={categories}
                      />
                    </Box>
                  );
                },
                // )
              )
            ) : (
              <NoData noText={t('There is no available live standings')} />
            )}
          </Box>
        </Container>
      </Box>
      <LiveStandingsMoodal openModal={openModal} setOpenModal={setOpenModal} />
      <RankingsMoodal openModal={openRankingModal} setOpenModal={setOpenRankingModal} />
      {selectedLiveStandings?.length > 0 && <ActionButtons selectedLiveStandings={selectedLiveStandings} setSelectedLiveStandings={setSelectedLiveStandings} />}
    </React.Fragment>
  );
};

export default LiveStandings;
